export const Colors = {
	Black: '#173F5F',
	White: '#FFFFFF',
	Orange: '#ED7951',
	Beige: '#F6F5F1',
	Gradient: {
		Left: '#386190',
		Right: '#2EB4BD',
	},
}
